<script setup lang="ts">
import { getVideoDeviceLiveApi } from '@/api/video'
import { getDeviceVideoUrl } from '@/utils/video'

const { visible, video, device } = useLinkageVideo(afterMessage)
const videoDeviceLive = ref<VideoDeviceLive>({})
const videoUrl = ref<string>('')
const seekable = ref<boolean>(false)
const channelOptions = ref<{ name: string, url: string }[]>([])
const channelName = ref<string>('')

async function afterMessage() {
  if (video.value && video.value.channels && video.value.channels.length > 0) {
    channelName.value = video.value.channels[0].name
    channelOptions.value.splice(0)
    channelOptions.value.push(...video.value.channels)
    await onChangeChannel(channelName.value)
  }
}

async function onChangeChannel(val: any) {
  if (!device.value || !device.value.deviceCode)
    return
  videoDeviceLive.value = await getVideoDeviceLiveApi(val, device.value?.deviceCode)
  seekable.value = videoDeviceLive.value.seekable as boolean
  videoUrl.value = getDeviceVideoUrl(videoDeviceLive.value.url ?? '', videoDeviceLive.value.proxied ?? false) as string
}

function onClose() {
  videoUrl.value = ''
  visible.value = false
}
</script>

<template>
  <a-modal
    :open="visible"
    :title="device?.deviceName ?? ''"
    centered
    :width="599"
    :footer="null"
    @cancel="onClose"
  >
    <a-select
      v-model:value="channelName"
      style="width: 120px"
      @change="onChangeChannel"
    >
      <a-select-option v-for="item in channelOptions" :key="item.name" :value="item.name">
        {{ item.name }}
      </a-select-option>
    </a-select>

    <div class="video">
      <video-player :has-audio="video?.audioEnabled ?? false" :video-url="videoUrl" :visible="visible" :seekable="seekable" />
    </div>
  </a-modal>
</template>

<style lang="scss" scoped>
.video {
  margin-top: 10px;
	height: 400px;
	width: 550px;
}
</style>
