<script setup lang="ts">
import { onBeforeUnmount, ref, watch } from 'vue'

interface Props {
  // 视频地址
  videoUrl: string
  visible: boolean
  //  是否有音频，传递该属性可以加快启动速度。默认不配置自动判断。
  hasAudio: boolean
  //   视频显示区域的宽高比，默认16:9
  aspect?: string
  //   是否有进度 有进度表示点播，没进度表示直播
  seekable: boolean
  //   是否显示倍速播放按钮。注意：当live为true时，此属性不生效  默认为true
  speed?: boolean
  decodeWasm?: boolean
  easyStretch?: boolean
  reconnection?: boolean
  showCustomButton?: boolean
}

const props = withDefaults(defineProps<Props>(), {})

const easyPlayerEl = ref()
// 循环次数
const circles = ref(0)
const intervalTimer = ref<number | null>(null)

let player: any

onBeforeUnmount(() => {
  destroy()
  player = null
})

watch(
  () => props.videoUrl,
  (newUrl) => {
    if (newUrl) {
      initPlayer()
    }
  },
)

function initPlayer() {
  if (player == null) {
    player = easyPlayerEl.value.getVueInstance()
  }
  else {
    player.initPlayer()
  }

  intervalTimer.value = setInterval(() => {
    if (player?.easyPlayer) {
      setVideoPreference()
    }

    circles.value++

    if (circles.value > 10) {
      clearIntervalTimer()
    }
  }, 1 * 1000)
}

function destroy() {
  player?.destroyPlayer()
  clearIntervalTimer()
}

function clearIntervalTimer() {
  clearInterval(intervalTimer.value as any)
  intervalTimer.value = null
  circles.value = 0
}

function setVideoPreference() {
  const controlBars = player.easyPlayer.controlBar.children_
  const playToggleEl = controlBars.find((item: any) => item.name_ === 'PlayToggle')

  const h265PlayToggleEl = document.querySelector('span.video-tools')?.firstElementChild?.firstElementChild

  if (!props.seekable) {
    playToggleEl && (playToggleEl.el_.style.display = 'none')
    if (h265PlayToggleEl) {
      (h265PlayToggleEl as HTMLElement).style.display = 'none'
    }
  }
  else {
    playToggleEl && (playToggleEl.el_.style.display = 'block')
    if (h265PlayToggleEl) {
      (h265PlayToggleEl as HTMLElement).style.display = 'flex'
    }
  }

  const voice = controlBars.find((item: any) => item.name_ === 'VolumePanel')

  const h265VoiceEl = document.querySelector('.video-tools')?.firstElementChild?.lastElementChild

  if (!props.hasAudio) {
    voice && (voice.el_.style.display = 'none')

    if (h265VoiceEl) {
      (h265VoiceEl as HTMLElement).style.display = 'none'
    }
  }
  else {
    voice && (voice.el_.style.display = 'block')
    if (h265VoiceEl) {
      (h265VoiceEl as HTMLElement).style.display = 'flex'
    }
  }
}
</script>

<template>
  <easy-player
    v-if="visible"
    ref="easyPlayerEl"
    :video-url="videoUrl"
    :live="!seekable"
    :muted="!hasAudio"
    :has-audio="hasAudio"
    :decode-wasm="decodeWasm"
    :easy-stretch="easyStretch"
    :reconnection="reconnection"
    :show-custom-button="showCustomButton"
    style="width: 100%;height: 100%;"
  />
</template>

<style>
.vjs-fluid{
  padding-top: 0 !important;
}
</style>

<style scoped lang="scss"></style>
