import { Button, notification } from 'ant-design-vue'
import { getDeviceByDeviceCodeApi } from '@/api/device'
import { getVideoByDeviceCodeApi } from '@/api/video'
import emitter from '@/utils/mitt'
import { closeSocket, openSocket } from '@/utils/websocket/WebsocketAction'

export function useLinkageVideo(afterMessageCall: Function) {
  const message = useMessage()
  const cacheVideoLinkages = new Map()
  const device = ref<Device>()
  const videoDevice = ref<Video>()
  const onlyVideoVisible = ref(false)

  onMounted(() => {
    openSocket()
    emitter.on('onVideo', onVideo)
  })

  onBeforeUnmount(() => {
    closeSocket()
    emitter.off('onVideo', onVideo)
  })

  async function onVideo(msg: any) {
    const device = await getDeviceByDeviceCodeApi(msg.videoDeviceCode)
    const videoDevice = await getVideoByDeviceCodeApi(msg.videoDeviceCode)
    if (!device || !videoDevice)
      return

    const key = `open${Date.now()}`
    cacheVideoLinkages.set(key, { device, videoDevice, msg })
    openNotification()
    openVideo(videoDevice, device)

    // 打开视频推送
    function openNotification() {
      notification.open({
        message: `视频联动${device.deviceName}`,
        description: msg.triggerDesc,
        duration: 5,
        placement: 'bottomRight',
        type: 'info',
        key,
        onClose: () => { cacheVideoLinkages.delete(key) },
        btn: () =>
          h(
            Button,
            {
              type: 'primary',
              onClick: () => onWatchLinkage(key),
            },
            { default: () => '查看视频' },
          ),
      })
    }
  }

  function onWatchLinkage(key: string) {
    const currentVideoLinkage = cacheVideoLinkages.get(key)
    if (!currentVideoLinkage)
      return
    // const msg = currentVideoLinkage.msg

    if (!videoDevice.value?.channels || videoDevice.value.channels.length === 0) {
      message.info('没有绑定视频通道，如有必要请联系实施工程师', 5)
    }
    else {
      onlyVideoVisible.value = true
      openVideo(currentVideoLinkage?.videoDevice, currentVideoLinkage?.device)
      // 出现视频
      notification.close(key)
      cacheVideoLinkages.delete(key)
    }
  }

  function openVideo(vd: any, d: any) {
    videoDevice.value = vd
    device.value = d
    onlyVideoVisible.value = true
    afterMessageCall()
  }

  return { visible: onlyVideoVisible, device, video: videoDevice }
}
