import emitter from '../mitt'
import WebsocketClient from './WebSocketClient'
import router from '@/router'

const message = useMessage()

class Singleton {
  private static instance: WebsocketClient

  private constructor() {
    // 私有构造函数，防止外部通过 new 关键字创建实例
  }

  public static getInstance(): WebsocketClient {
    if (!Singleton.instance) {
      Singleton.instance = new WebsocketClient()
    }
    return Singleton.instance
  }
}

export function initialize() {
  if (!window.WebSocket) {
    console.warn('浏览器不支持websocket')
    return
  }

  if (Singleton.getInstance().isWebSocketOpened())
    return

  const connUrl = `${(location.protocol === 'https:' ? 'wss://' : 'ws://') + location.host}/websocket/engine`
  Singleton.getInstance().initialize(connUrl)
  Singleton.getInstance().setEventCallback(handleEvent)
  Singleton.getInstance().setReconnectCallback(handleReconnect)
}

export function handleEvent(msg: WSBaseMessage) {
  switch (msg.messageType) {
    // 授权成功
    case 'AUTHED':
      // 开启心跳
      Singleton.getInstance().startHeart()
      break

      // 授权失败
    case 'NO_AUTH':
      message.warning('鉴权失败，请重新登录')
      setTimeout(async () => {
        await router.push('/login')
      }, 1000)
      break

      // 订阅设备属性值和事件
    case 'SUBSCRIBE_DEVICE':
      break

      // 取消订阅设备属性值和事件
    case 'UNSUBSCRIBE_DEVICE':
      break

      // 推送设备实时属性值
    case 'PUSH_DEVICE_PROPERTY':{
      const data = msg.valueMap
      emitter.emit('onSubscribeDevice', data)
      break
    }

    // 推送设备图标
    case 'PUSH_DEVICE_ICON':
      emitter.emit('onDeviceIconChenged', msg)
      break

      // 视频联动推送
    case 'VIDEO':
      emitter.emit('onVideo', msg)
      break

      // 设备在线离线推送
    case 'PUSH_DEVICE_ONOFFLINE':
      emitter.emit('onDeviceOnOffLine', msg)
      break

      // 平台事件告警
    case 'PUSH_PLATFORM_EVENT_TRIGGER':
      emitter.emit('onPushPlatformEventTrigger', msg as any)
      break
      // 平台事件告警消失
    case 'PUSH_PLATFORM_EVENT_DISAPPEAR':
      emitter.emit('onPushPlatformEventDisappear', msg as any)
      break

    default:
  }
}

export function handleReconnect() {
  Singleton.getInstance().setConnNull()
  initialize()
}

export function getWebSocket() {
  return Singleton.getInstance()
}

export function closeSocket() {
  Singleton.getInstance().close()
}

export function openSocket() {
  initialize()
}
