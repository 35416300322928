const CookieSid = 'chenye-iot-canary-sid'

function getCookie() {
  const cookieStr = document.cookie
  const cookieArr = cookieStr.split(';')
  const obj: any = {}
  cookieArr.forEach((item) => {
    const itemArr = item.split('=')
    obj[itemArr[0].trim()] = itemArr[1].trim()
  })

  return obj[CookieSid]
}

export default class WebsocketClient {
  private conn: WebSocket | null
  private connectionUrl: string | null
  private readonly connectFailCount: number
  private reconnectCallback: (() => void) | null
  // private firstConnectCallback: (() => void) | null;
  private eventCallback: ((msg: any) => void) | null
  private heartTimer: any
  // 订阅的设备编号
  private subscribeDevices: Set<string> = new Set()

  constructor() {
    this.conn = null
    this.connectionUrl = null
    this.connectFailCount = 0
    this.reconnectCallback = null
    // this.firstConnectCallback = null;
    this.eventCallback = null
  }

  isWebSocketOpened() {
    return this.conn != null
  }

  public initialize(connectionUrl = this.connectionUrl) {
    if (this.conn)
      return

    if (connectionUrl == null) {
      console.log(this.reconnectCallback, 'reconnection call back')

      console.warn('websocket 必须有一个连接url')
      return
    }

    if (this.connectFailCount === 0) {
      console.log(`websocket正在连接到：${connectionUrl}`)
    }

    this.conn = new WebSocket(connectionUrl)

    this.connectionUrl = connectionUrl

    this.conn.onopen = () => {
      // console.log("已经连接");
      this.onAuth()
    }

    this.conn.onclose = (e) => {
      console.log(e, 'websocket断开了')
      this.stopHeart()
      if (e.code === 1006) {
        // console.log('非正常断开');
        this.reconnectCallback && this.reconnectCallback()
        // 非正常关闭，重连之后，订阅设备
        this.onSubscribeDevice([...this.subscribeDevices])
      }
      else {
        // 关闭websocket，清空订阅设备列表
        this.subscribeDevices.clear()
      }
    }

    this.conn.onerror = () => {
      // console.log("发生错误了", e);
      console.log('')
    }

    this.conn.onmessage = (event) => {
      if (event.data !== 'pong') {
        const msg = JSON.parse(event.data)
        if (this.eventCallback) {
          this.eventCallback(msg)
        }
      }
    }
  }

  public setEventCallback(callback: (msg: any) => void) {
    this.eventCallback = callback
  }

  public setConnNull() {
    this.conn = null
  }

  public setReconnectCallback(callback: () => void) {
    this.reconnectCallback = callback
  }

  close() {
    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.stopHeart()
      this.conn.close(1000, 'HANDLE_CLOSE')
      this.conn = null
    }
  }

  onAuth() {
    const msg = {
      httpSessionId: getCookie(),
    }

    this.sendMessage('AUTH', msg)
  }

  // 订阅属性值和事件
  onSubscribeDevice(deviceCodes: string[]) {
    // 将订阅的设备添加到设备列表中
    deviceCodes.forEach(this.subscribeDevices.add, this.subscribeDevices)

    const msg = {
      deviceCodes,
    }

    this.sendMessage('SUBSCRIBE_DEVICE', msg)
  }

  // 取消订阅设备属性值和事件
  onUnSubscribeDevice(deviceCode?: string) {
    // 单个取消订阅，删除订阅列表里的设备编号
    deviceCode == null ? this.subscribeDevices.clear() : this.subscribeDevices.delete(deviceCode)
    // deviceCode的值给null，表示取消全部订阅
    const msg = {
      deviceCode,
    }

    this.sendMessage('UNSUBSCRIBE_DEVICE', msg)
  }

  // 发送websocket消息
  sendMessage(messageType: SocketMessageType, data: any) {
    const msg = {
      messageType,
      ...data,
    }

    if (this.conn && this.conn.readyState === WebSocket.OPEN) {
      this.conn.send(JSON.stringify(msg))
    }
    else if (!this.conn || this.conn.readyState === WebSocket.CLOSED) {
      this.conn = null
      this.initialize()
    }
  }

  // 开启心跳
  startHeart() {
    if (this.conn) {
      this.heartTimer = setInterval(() => {
        this.conn?.send('ping')
      }, 30000)
    }
  }

  // 结束心跳
  stopHeart() {
    if (this.heartTimer) {
      clearInterval(this.heartTimer)
      this.heartTimer = null
    }
  }
}
