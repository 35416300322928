/**
 * 分页查询设备
 */
export const queryDevicesApi = (params: DeviceQueryParam) => useGet<DevicePaging<Device>, DeviceQueryParam>('/deploy/device/page', params)

/**
 * 根据id删除设备
 */
export const deleteDeviceApi = (id: number) => useDelete<boolean>(`/deploy/device/${id}`)

/**
 * 通过设备编号获取设备信息
 */
export const getDeviceByDeviceCodeApi = (deviceCode: string) => useGet<Device>('/deploy/device', { deviceCode })

/**
 * 分页查询设备的历史原生事件
 */
export const queryHistoryDeviceEventApi = (params: HistoryEventOfDeviceParam) => useGet<Paging<HistoryEventOfDevice>>('/runtime/history-device-event/page', params)

/**
 * 首页设备统计
 */
export const getDeviceStatisticApi = () => useGet<EventSubTypeRecord>('/runtime/device/count')

/**
 * 修改设备
 */
export const updateDeviceApi = (params: any) => usePut('/deploy/device', params)

/**
 * 新增设备
 */
export const addDeviceApi = (params: any) => usePost('/deploy/device', params)

/**
 * 查询最近几天在线离线设备数量
 */
export const historyOnlineCountsApi = (days: number = 7) => useGet('/runtime/history-online-count/list-last-days', { days })

/**
 * 查询最近几天告警故障趋势
 */
export const historyPlatformEventsLastDaysTrendApi = (days: number = 7) => useGet('/runtime/history-platform-event/last-days-trend', { days })
