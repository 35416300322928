/**
 * 根据后端给的视频地址决定最终实际要使用的视频地址
 * @link https://www.npmjs.com/package/@easydarwin/easyplayer
 * @link https://github.com/tsingsee/EasyPlayer.js
 * @link http://www.easydarwin.org/easyplayer/
 * @param origin 后端给的视频地址
 * @param proxied true表示后端给的视频地址是相对的，即：不包含协议、域名、端口，需前端自行根据浏览器里的网址加以拼接。
 * @return 返回空值表示不支持的视频格式
 */
export function getDeviceVideoUrl(origin: string, proxied: boolean): string | null {
  const indexOfQuestionMark: number = origin.indexOf('?')
  let lowerCaseOrigin: string // 去除问号及后面的部分并且全转为小写
  if (indexOfQuestionMark !== -1) {
    lowerCaseOrigin = origin.substring(0, indexOfQuestionMark).toLowerCase()
  }
  else {
    lowerCaseOrigin = origin.toLowerCase()
  }
  let videoUrl: string | null
  if (lowerCaseOrigin.endsWith('.flv')) {
    if (proxied) {
      videoUrl = (location.protocol === 'https:' ? 'wss://' : 'ws://') + location.host + (lowerCaseOrigin.startsWith('/') ? '' : '/') + origin
    }
    else {
      videoUrl = origin
    }
  }
  else if (
    lowerCaseOrigin.endsWith('.m3u8')
    || lowerCaseOrigin.endsWith('.mp4')
    || lowerCaseOrigin.endsWith('.ogg')
    || lowerCaseOrigin.endsWith('.webm')
  ) {
    // 注意：chrome不支持h265的mp4、ogg、webm，此时其通常的表现是只有声音没有画面，而edge、safari等浏览器是支持的！
    // 注意：对于mp4、ogg、webm格式的视频，底层其实还是直接调用了浏览器的原生支持能力！
    if (proxied) {
      videoUrl = location.origin + (lowerCaseOrigin.startsWith('/') ? '' : '/') + origin
    }
    else {
      videoUrl = origin
    }
  }
  else {
    videoUrl = null
  }
  return videoUrl
}
