<script setup lang="ts">
interface Props {
  videoUrl: string
  // 是否有音频，如果设置false，则不对音频数据解码，提升性能。
  hasAudio: boolean
  visible: boolean
  // 显示网速
  showBandwidth?: boolean
  // 加载中的文字
  loadingText?: string
  // 是否显示全屏按钮
  fullscreen?: boolean
  // 是否显示播放暂停按钮
  seekable: boolean
  // 是否不使用离屏模式（提升渲染能力）
  forceNoOffscreen?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  showBandwidth: true,
  loadingText: '加载中....',
  fullscreen: true,

  forceNoOffscreen: true,
})

const container = ref(null)
let jessibuca: any = null
// const playUrl = ref("http://flv.bdplay.nodemedia.cn/live/bbb.flv");

onMounted(() => {
  props.visible && initPlayer()
})

onBeforeUnmount(() => {
  destroy()
})

function initPlayer() {
  createJessibuca()
  addPlayerListener()
}

function destroy() {
  jessibuca && jessibuca.destroy()
  jessibuca = null
}

watch(
  () => props.videoUrl,
  (newUrl) => {
    if (newUrl) {
      play()
    }
  },
)

// 创建视频播放器
function createJessibuca() {
  jessibuca = new (window as any).Jessibuca({
    decoder: '/jessibuca/decoder.js',
    container: container.value,
    hasAudio: props.hasAudio,
    videoBuffer: 0.2, // 缓存时长
    isResize: false, // 视频画面做等比缩放后,高或宽对齐canvas区域,画面不被拉伸,但有黑边。
    loadingText: props.loadingText,
    showBandwidth: props.showBandwidth, // 显示网速
    operateBtns: {
      fullscreen: props.fullscreen, // 是否显示全屏按钮
      screenshot: true, // 是否显示截图按钮
      // 是否显示播放暂停按钮 seekable表示是否有进度，
      // 有进度表示是点播，此时有播放按钮，
      // 没进度表示是直播，没有播放按钮
      play: props.seekable,
      audio: props.hasAudio, // 是否显示声音按钮
      record: false, // 是否显示录制按钮
    },
    forceNoOffscreen: true, // 是否不使用离屏模式（提升渲染能力） 某些版本的浏览器会出现内存无缘无故变大的情况。谨慎使用
    isNotMute: props.hasAudio, // 是否开启声音，默认是关闭声音播放的(false)。 必须通过按钮或者点击事件触发play() 方法，才会播放出声音来，不然还是静音的
  })
}

function play() {
  jessibuca.play(props.videoUrl)
}

function addPlayerListener() {
  jessibuca.on('load', () => {})

  jessibuca.on('log', (_msg: any) => {})

  jessibuca.on('record', (_msg: any) => {})

  jessibuca.on('pause', () => {})

  jessibuca.on('play', () => {})

  jessibuca.on('fullscreen', (_msg: any) => {})

  jessibuca.on('mute', (_msg: any) => {})

  jessibuca.on('mute', (_msg: any) => {})

  jessibuca.on('audioInfo', (_msg: any) => {})

  jessibuca.on('videoInfo', (_info: any) => {})

  jessibuca.on('error', (_error: any) => {})

  jessibuca.on('timeout', () => {})

  jessibuca.on('start', () => {})

  // 渲染性能统计，流开始播放后回调，每秒1次。
  // 0: 表示卡顿
  // 1: 表示流畅
  // 2: 表示非常流程
  jessibuca.on('performance', (_performance: any) => {
    // 0: 表示卡顿
    // 1: 表示流畅
    // 2: 表示非常流程
  })
  jessibuca.on('buffer', (_buffer: any) => {})

  //  流状态统计，流开始播放后回调，每秒1次。
  // buf: 当前缓冲区时长，单位毫秒,
  // fps: 当前视频帧率,
  // abps: 当前音频码率，单位bit,
  // vbps: 当前视频码率，单位bit，
  // ts:当前视频帧pts，单位毫秒
  jessibuca.on('stats', (_stats: any) => {})

  // 当前网速， 单位KB 每秒1次,
  jessibuca.on('kBps', (_kBps: any) => {})

  // 显示时间戳 PTS
  jessibuca.on('videoFrame', () => {})

  jessibuca.on('metadata', () => {})
}
</script>

<template>
  <div id="container" ref="container" />
</template>

<style scoped lang="scss">
#container {
	background: rgba(13, 14, 27, 0.7);
	width: 100%;
	height: 100%;
}
</style>
