<script setup lang="ts">
import CustomEasyPlayer from './custom-easy-player/index.vue'
import JessibucaPlayer from './jessibuca/index.vue'
// import { ClientStore } from '/@/utils/singleton'

interface Props {
  videoUrl: string
  hasAudio: boolean
  seekable: boolean
  visible: boolean
  videoPlayerStatus?: 'JESSIBUCA' | 'EASY_PLAYER'
}

const props = withDefaults(defineProps<Props>(), {})

const videoStatus = useStorage<VideoPlayerType>('video_status', null)

const easyPlayerVisible = computed(() => {
  if (props.videoPlayerStatus) {
    return props.videoPlayerStatus === 'EASY_PLAYER' && props.visible
  }
  return videoStatus.value === 'EASY_PLAYER' && props.visible
})

const jessibucaPlayerVisible = computed(() => {
  if (props.videoPlayerStatus) {
    return props.videoPlayerStatus === 'JESSIBUCA' && props.visible
  }
  return videoStatus.value === 'JESSIBUCA' && props.visible
})
</script>

<template>
  <div style="width: 100%;height: 100%;">
    <CustomEasyPlayer
      v-if="easyPlayerVisible"
      :visible="easyPlayerVisible"
      :video-url="videoUrl"
      :seekable="seekable"
      :has-audio="hasAudio"
      :decode-wasm="true"
      :easy-stretch="false"
      :reconnection="true"
      :show-custom-button="true"
    />
    <JessibucaPlayer v-if="jessibucaPlayerVisible" :visible="jessibucaPlayerVisible" :video-url="videoUrl" :seekable="seekable" :has-audio="hasAudio" />
  </div>
</template>

<style scoped lang="scss"></style>
